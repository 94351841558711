.scroll {
  height: 81vh;
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0.7em;
  background-color: lightgray;
}

::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

.inpwidth {
  width: 5px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
