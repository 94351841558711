body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}
@media screen {
  div.divFooter {
    display: none;
  }
}

@media print {
  .col-xxl-4.col-xl-4.col-lg-4.col-md-6.col-12 {
    width: 33.33333333% !important;
  }
  .col-xxl-8.col-xl-8.col-lg-8.col-md-6.col-12 {
    width: 66.66666666% !important;
  }
  .col-xxl-3.col-xl-3.col-lg-3.col-md-6.col-12 {
    width: 25% !important;
  }
  p,
  label {
    margin: 0 !important;
  }
  .pagebreak {
    page-break-after: always;
  }
  .mt-2,
  .mb-2 {
    margin: 0 !important;
  }
  footer {
    display: inline-block;
  }
  div.divFooter {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
