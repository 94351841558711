.input-icon {
    position:relative;
  }
  .icon {
    position: absolute;
    left:10px;
    top: 5px;
    cursor: pointer;
  }
  .icon:hover {
    opacity: 0.5;
  }
  .table-sticky2{
    position: sticky;
    top: 0; /* Sticks the first row to the very top */
    z-index: 1030; /* Ensure it is above the second row */
    background-color: #fff; /* Add a background color */
  }
  
  .table-sticky1  {
    position: sticky;
    top: 40px; /* Adjust this value to match the height of the first row */
    z-index: 1020; /* Ensure it appears below the first row */
    background-color: #fff; /* Add a background color */
  }
  
  
